import axios from 'axios';

import config from '../config';
import {
  getItem,
  PREV_PATH,
  removeItem,
  setItem,
  USER,
} from "../hooks/useLocalStorage";


export default (url?: string) => {
  const customAxios = axios.create(
    {baseURL: url || config.apiUrl}
  );

  customAxios.interceptors.request.use(
    (config) => {
      const userCode = getItem(USER);
      const user = userCode ? JSON.parse(userCode) : null;

      if (user && user.accessToken) {
        config.headers.authorization = `Bearer ${user.accessToken}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  customAxios.interceptors.response.use(
    response => response,
    err => {
      if (err.response?.status === 401) {
        removeItem(USER);
        setItem(PREV_PATH, JSON.stringify(window.location.pathname));
        window.location.assign('/login');
      }

      return Promise.reject(err);
    }
  );

  return customAxios;
};
