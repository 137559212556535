import {
  Text,
  Input,
  FormControl,
  FormLabel,
  Button,
  FormErrorMessage,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure
} from '@chakra-ui/react';
import {zodResolver} from '@hookform/resolvers/zod'
import React, {useEffect, useState} from "react";
import {z} from 'zod'
import {useForm} from 'react-hook-form';
import {
  useUpdateProfileSettings,
} from 'api/user-api';
import {
  useChangePassword,
} from 'api/user-api';
import {useLocalStorage} from 'hooks/useLocalStorage';
import {useToast} from '@chakra-ui/react'


export default function ProfileSettings(editor: any) {
  const toast = useToast()
  const [user] = useLocalStorage("user", "");
  const [showPassword, setShowPassword] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordAgain, setNewPasswordAgain] = useState('');
  const [modalMessage, setModalMessage] = useState('');
  const {isOpen, onOpen, onClose} = useDisclosure()

  const profileSettingSchema = z.object({
    name: z.string().nonempty('Name is required'),
    email: z.string().nonempty('Email is required').email('Invalid email format'),
    phone: z.string().nonempty('Phone is required'),
  });

  const defaultValues = {
    name: editor?.editor?.name,
    email: editor?.editor?.email,
    phone: editor?.editor?.phone,
  }

  const {
    register,
    handleSubmit,
    formState: {errors},
    getValues,
  } = useForm({
    defaultValues: defaultValues,
    mode: 'all',
    resolver: zodResolver(profileSettingSchema),
  })

  const [
    {error: updateProfileError},
    updateProfileSettings,
  ] = useUpdateProfileSettings()

  const [
    {
      data,
      loading,
      error,
    },
    changePassword,
  ] = useChangePassword()

  const toaster = (title: string) => {
    return toast({
      title,
      status: 'success',
      duration: 3000,
    });
  }

  useEffect(() => {
    if (updateProfileError) {
      console.error('___',updateProfileError);
      toast({
        title: 'Server error',
        description: updateProfileError?.response?.data?.error || `Can't update profile settings`,
        status: 'error',
        isClosable: true,
      });
    }
  }, [updateProfileError]);


  const handlePasswordChange = async () => {
    try {
      if (newPassword !== newPasswordAgain) {
        setModalMessage('Passwords do not match')
        return;
      }
      if (newPassword.length < 8) {
        setModalMessage('Password should conatain at least 8 characters')
        return;
      }

      await changePassword({data: {old_p: currentPassword, new_p: newPassword}})

      setModalMessage('')
      setCurrentPassword('')
      setNewPassword('')
      setNewPasswordAgain('')

      onClose()
      toaster('Password changed sucesfully')
    } catch (error: any) {
      setModalMessage(error.response.data.error_message)
    }
  }
  const handleSaveClick = async () => {
    try {
      const formData = getValues()
      await updateProfileSettings({
        data: formData,
        params: {
          userId: user.userId
        }
      })
      toaster('Profile data updeted sucesfully')
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };


  const togglePassword = () => {
    setShowPassword(!showPassword);
  };


  return (
    <form onSubmit={handleSubmit(handleSaveClick)}>
      <FormControl id="name" mb={4} isInvalid={!!errors.name}>
        <FormLabel>Name</FormLabel>
        <Input
          variant='main'
          {...register('name')}
        />
        <FormErrorMessage>
          {errors.name && errors.name.message?.toString()}
        </FormErrorMessage>
      </FormControl>

      <FormControl id="email" mb={4} isInvalid={!!errors.email}>
        <FormLabel>Email</FormLabel>
        <Input
          {...register('email')}
          variant='main'
        />
        <FormErrorMessage>
          {errors.email && errors.email.message?.toString()}
        </FormErrorMessage> </FormControl>

      <FormControl id="phone" mb={4} isInvalid={!!errors.phone}>
        <FormLabel>Phone number</FormLabel>
        <Input {...register('phone')}
               variant='main'
        />
        <FormErrorMessage>
          {errors.phone && errors.phone.message?.toString()}
        </FormErrorMessage>
      </FormControl>
      <Button
        variant='photonLight'
        color='white'
        onClick={onOpen}
        w='auto'
        mr='10px'
      >
        Change password
      </Button>

      <Button
        variant='photonDark'
        onClick={handleSaveClick}
      >
        Save
      </Button>


      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay/>
        <ModalContent>
          <ModalHeader>Change Password</ModalHeader>
          <ModalCloseButton/>
          <ModalBody>
            <FormControl id="oldPassword" mb={4}>
              <FormLabel>Current password</FormLabel>
              <Input
                variant='main'
                type={showPassword ? "text" : "password"}
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
              />
            </FormControl>

            <FormControl id="newPassword1" mb={4}>
              <FormLabel>New password</FormLabel>
              <Input
                variant='main'
                type={showPassword ? "text" : "password"}
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </FormControl>

            <FormControl id="newPassword2" mb={4}>
              <FormLabel>New password again</FormLabel>
              <Input
                variant='main'
                type={showPassword ? "text" : "password"}
                value={newPasswordAgain}
                onChange={(e) => setNewPasswordAgain(e.target.value)}
              />
            </FormControl>

            <Button h="1.75rem" size="sm" onClick={togglePassword}>
              {showPassword ? "Hide passwords" : "Show passwords"}
            </Button>

            {
              modalMessage
              && <Text color="red.500">{modalMessage}</Text>
            }
          </ModalBody>

          <ModalFooter>
            <Button
              variant='photonMedium'
              mr={3}
              onClick={onClose}
            >
              Back
            </Button>
            <Button
              variant='photonDark'
              isLoading={loading}
              w='auto'
              loadingText="Please wait..."
              onClick={handlePasswordChange}
            >
              Save new password
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </form>
  );
}
