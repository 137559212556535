import {
  createContext,
  ReactElement,
  useContext,
  useMemo,
  useState,
} from "react";

import {
  useLocalStorage,
  USER,
} from "./useLocalStorage";


const AuthContext = createContext(null);

export const AuthProvider = ({ children, userData=null }: {children: ReactElement, userData?: any}) => {
  const [userInStorage, saveUser, clearUser] = useLocalStorage(USER, userData);
  const [user, setUser] = useState(userInStorage);

  const login = (user: any) => {
    setUser(user);
    if (user) {
      saveUser(user);
    } else {
      clearUser();
    }
  };

  const logout = () => {
    login(null);
  };

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
    }),
    [user]
  );
  return <AuthContext.Provider value={value}>
    {children}
  </AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
