import { useState } from "react";


export const PREV_PATH = 'previousPath';
export const USER = 'user';
export const LAST_SEEN = 'lastSeen';

export const getItem = (keyName: string) => {
  return window.localStorage.getItem(keyName);
}

export const removeItem = (keyName: string) => {
  window.localStorage.removeItem(keyName);
}

export const setItem = (keyName: string, keyValue: string) => {
  window.localStorage.setItem(keyName, keyValue);
}

export const useLocalStorage = (keyName: string, defaultValue: any) => {
  const [storedValue, setStoredValue] = useState(
    () => {
      try {
        const value = getItem(keyName);
        if (value) {
          return JSON.parse(value);
        } else {
          setItem(keyName, JSON.stringify(defaultValue));
          return defaultValue;
        }
      } catch (err) {
        return defaultValue;
      }
    }
  );

  const setValue = (newValue: any) => {
    try {
      setItem(keyName, JSON.stringify(newValue));
    } catch (err) {}
    setStoredValue(newValue);
  };

  const removeValue = () => {
    try {
      removeItem(keyName);
    } catch (err) {}
    setStoredValue(undefined);
  }

  return [storedValue, setValue, removeValue];
};
